import $ from 'jquery';

$(document).ready(function() {
		
	/*OPEN A FIXED SEARCH BOX*/

	$(".search__desktop i").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeIn(300);
	});

	$(".searchbox i").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeOut(300);
	});

	/*
	function makeTimer() {

		//		var endTime = new Date("29 April 2018 9:56:00 GMT+01:00");
		var endTime = new Date("27 February 2023 9:30:00 GMT+01:00");
		endTime = (Date.parse(endTime) / 1000);

		var now = new Date();
		now = (Date.parse(now) / 1000);

		var timeLeft = endTime - now;

		var days = Math.floor(timeLeft / 86400);
		var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
		var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
		var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));

		if (hours < "10") { hours = "0" + hours; }
		if (minutes < "10") { minutes = "0" + minutes; }
		if (seconds < "10") { seconds = "0" + seconds; }

		$("#days").html(days + "<span>jours</span>");
		$("#hours").html(hours + "<span>heures</span>");
		$("#minutes").html(minutes + "<span>minutes</span>");
		$("#seconds").html(seconds + "<span>secondes</span>");

	}

	setInterval(function() { makeTimer(); }, 1000);

	 */
});
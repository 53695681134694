import $ from 'jquery';
//import AOS from 'aos';

$(document).ready(function() {

    //MATCH HEIGHT EQUAL HEIGHT

    $('.matchHeight').matchHeight();


    /* SWITCHER
    $('.toggle-btn').click(function() {
        $(this).parent().toggleClass('active').siblings().removeClass('active');
        //$(this).parent().find('.single_switcher__active').toggleClass('active').siblings().removeClass('active');
    });
     */

    /* AOS ANIMATION FUNCTION

    $(function() {
        AOS.init();
    });
     */

    $('.btn_inscription').on('click', function() {
        $('#inscription').removeClass('d-none');
        $('#inscription').addClass('d-block');
        $('#connexion').addClass('d-none');
    });

    $('.btn_connexion').on('click', function() {
        $('#connexion').removeClass('d-none');
        $('#connexion').addClass('d-block');
        $('#inscription').addClass('d-none');
    });

    $('.btn_compte').on('click', function() {
        $('#mon-compte').removeClass('d-none');
        $('#mon-compte').addClass('d-block');
    });

    $('.acf-checkbox-toggle').parent().addClass('change-label');
    //$('label.change-label').val("Tout séléctionner");


	$(document).on('click', '#click_inscription_form', function (e) {
		e.preventDefault(); // Empêche le comportement par défaut de l'événement de clic (le défilement de la page)
		$('#inscription').removeClass('d-none');
		$('#inscription').addClass('d-block');
		$('html, body').animate({
			scrollTop: $('#inscription').offset().top
		}, 500);  // Changez 1000 (qui est en millisecondes) en la durée de l'animation que vous voulez
	});

});